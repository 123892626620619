import { attrRankController } from '~/apis/back/services'
import type { RankTypeDto } from '~/apis/back/types'
import { ENUM_EventType, ENUM_PageName, E_RANK_TYPE } from '~/enums'
import type { RankClicksClickEvent } from '~/types/event-tracking'

export function useAttrOrExperTabs() {
  const filterConditionsStore = useFilterConditionsStore()
  const route = useRoute()
  const router = useRouter()
  const gtm = useGtm()
  const { locale } = useLocale()
  const { t } = useI18n()

  const tabList = useState<RankTypeDto[]>('azgo_experience_tab_list', () => [])

  const currentTab = computed<E_RANK_TYPE>(() => {
    return [E_RANK_TYPE.ALL, E_RANK_TYPE.ATTRACTIONS, E_RANK_TYPE.EXPERIENCE][Number(route.query.tab_key) || 0]
  })

  async function fetchTabList() {
    const { data: res } = await attrRankController.rankTypeListPost({
      destinationId: Number(route.query?.destinationId),
    })

    if (res.value?.data?.rankTypeList?.length) {
      tabList.value = [
        ...(route.query.query
          ? [{
              rankType: E_RANK_TYPE.ALL,
              rankTypeName: t('key.attraction.tab.all'),
            }]
          : []),
        ...res.value?.data?.rankTypeList,
      ]
    }
    return tabList.value
  }

  const handleChangeTab = (rankType: string) => {
    const scrollContainer = document.querySelector('#scroll-container') as HTMLDivElement
    const scrollTop = 240
    if (scrollContainer.scrollTop > scrollTop) {
      scrollContainer.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      })
    }
    router.replace({
      query: {
        ...route.query,
        tab_key: [E_RANK_TYPE.ALL, E_RANK_TYPE.ATTRACTIONS, E_RANK_TYPE.EXPERIENCE].findIndex(item => item === rankType),
      },
    })
    trackRankClicksEvent()
  }

  function trackRankClicksEvent() {
    const params: RankClicksClickEvent = {
      event: ENUM_EventType.RankClick,
      page_category: ENUM_PageName.List,
      locale: locale.value,
      city_id: filterConditionsStore.filterConditions.selectedCity?.destinationId,
      city: filterConditionsStore.filterConditions.selectedCity?.destinationName,
      country_code: filterConditionsStore.filterConditions.selectedCity?.countryCode,
      campaign_id: route.query.campaign_id as string ?? '',
      campaign_name: route.query.campaign_name as string ?? '',
      rank_id: currentTab.value,
    }
    gtm?.trackEvent(params)
  }

  watch(() => route.query.query, () => {
    if (!route.query.query) {
      tabList.value = tabList.value.filter(item => item.rankType !== E_RANK_TYPE.ALL)
      return
    }
    if (route.query.query && !tabList.value.find(item => item.rankType === E_RANK_TYPE.ALL)) {
      tabList.value.unshift(
        {
          rankType: E_RANK_TYPE.ALL,
          rankTypeName: t('key.attraction.tab.all'),
        },
      )
    }
  })

  return {
    tabList,
    currentTab,
    fetchTabList,
    handleChangeTab,
  }
}
